//tabs lista
//$('#myTab, #myTab2, #myTab3, #myTab4').tabCollapse();
$('#myTab').tabCollapse();

//menu header
$(document).ready(function(){
    $(".slidingDiv").hide();
    $(".show_hide").show();
    $(".slidingDiv").fadeOut( "slow", function() {
    // Animation complete.
    });
    $(".show_hide, .slidingDiv a").click(function(){
        $(".slidingDiv").slideToggle();
    });

});

//smooth scroll
$(function() {
    $('.slidingDiv a[href*="#"]:not([href="#"]), .btsTopBaner a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });
});


(function() {

    "use strict";

    //muda estado do icone do menu
    var toggles = document.querySelectorAll(".c-hamburger");

    for (var i = toggles.length - 1; i >= 0; i--) {
        var toggle = toggles[i];
        toggleHandler(toggle);
    };

    function toggleHandler(toggle) {
        toggle.addEventListener( "click", function(e) {
            e.preventDefault();
            (this.classList.contains("is-active") === true) ? this.classList.remove("is-active") : this.classList.add("is-active");
        });
    }

})();

/*$('.slidingDiv a').on('click', function(){
    $('.c-hamburger').toggleClass('is-active');
});*/

//tabs quadrado
$(".qTop:nth-of-type(2)").addClass("quadradosTopefeito");
$(".qTop:nth-of-type(2)").removeClass("quadradosTop");
$("#newboxes2").show();
$("#newboxes3").hide();
$("#newboxes4").hide();


$(".qTop:nth-of-type(2)").on("click", function () {
    $(this).addClass("quadradosTopefeito"); 
    $(this).removeClass("quadradosTop");
    $(".qTop:nth-of-type(3)").addClass("quadradosTop");
    $(".qTop:nth-of-type(3)").removeClass("quadradosTopefeito");
    $(".qTop:nth-of-type(4)").addClass("quadradosTop");
    $(".qTop:nth-of-type(4)").removeClass("quadradosTopefeito");
    $("#newboxes2").show("slow", function () {// Animation complete.
    });
    $("#newboxes3").hide("slow", function () {// Animation complete.
    });
    $("#newboxes4").hide("slow", function () {// Animation complete.
    });
});

$(".qTop:nth-of-type(3)").on("click", function () {
    $(this).addClass("quadradosTopefeito"); 
    $(this).removeClass("quadradosTop");
    $(".qTop:nth-of-type(2)").addClass("quadradosTop");
    $(".qTop:nth-of-type(2)").removeClass("quadradosTopefeito");
    $(".qTop:nth-of-type(4)").addClass("quadradosTop");
    $(".qTop:nth-of-type(4)").removeClass("quadradosTopefeito");
    $("#newboxes3").show("slow", function () {// Animation complete.
    });
    $("#newboxes2").hide("slow", function () {// Animation complete.
    });
    $("#newboxes4").hide("slow", function () {// Animation complete.
    });
});

$(".qTop:nth-of-type(4)").on("click", function () {
    $(this).addClass("quadradosTopefeito"); 
    $(this).removeClass("quadradosTop");
    $(".qTop:nth-of-type(3)").addClass("quadradosTop");
    $(".qTop:nth-of-type(3)").removeClass("quadradosTopefeito");
    $(".qTop:nth-of-type(2)").addClass("quadradosTop");
    $(".qTop:nth-of-type(2)").removeClass("quadradosTopefeito");
    $("#newboxes4").show("slow", function () {// Animation complete.
    });
    $("#newboxes2").hide("slow", function () {// Animation complete.
    });
    $("#newboxes3").hide("slow", function () {// Animation complete.
    });
});

//estado do header durante scroll
$(document).on("scroll",function(){
    if($(document).scrollTop()>100){ 
        $("headerx").addClass("smaller");
    } else {
        $("headerx").removeClass("smaller");
    }
});

//inicializa WOW
new WOW().init();

//botao de volta ao topo
$(document).ready(function () {

    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.topo').fadeIn();
        } else {
            $('.topo').fadeOut();
        }
    });

    $('.topo').click(function () {
        $("html, body").animate({
            scrollTop: 0
        }, 600);
        return false;
    });

});